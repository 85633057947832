export const experienceData = [
  {
    id: 1,
    company: "NORESCO",
    jobtitle: "Senior Energy Analyst",
    startYear: "2019",
    endYear: "Present",
  },
  {
    id: 2,
    company: "EnergySoft",
    jobtitle: "Senior Nonresidential Energy Analyst",
    startYear: "2014",
    endYear: "2019",
  },
];

// deploy trigger
