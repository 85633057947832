export const educationData = [
  {
    id: 1,
    institution: "Colorado State University",
    course: "B.S. Computer Science",
    startYear: "",
    endYear: "",
  },
  {
    id: 2,
    institution: "Sonoma State University",
    course: "B.S. Energy Management and Design",
    startYear: "",
    endYear: "",
  },
];
